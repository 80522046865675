import { useJsApiLoader } from '@react-google-maps/api';
import { config } from 'config';
import { useMemo } from 'react';

export default function useMapsAPI() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.maps.key,
    version: '3.59',
    libraries: useMemo(() => (['places']), []),
  });

  return {
    mapsApiLoaded: isLoaded,
  };
}
