import { LatLng } from 'leaflet';
import { BoundariesWhereInput } from 'api/types/globalTypes';

const latWithinRange = (n: number) => Math.min(90, Math.max(-89.999999, n));
const lngWithinRange = (n: number) => Math.min(180, Math.max(-179.999999, n));

export const latLngToPoint = (latLng: LatLng) => ({
  type: 'Point',
  coordinates: [latWithinRange(latLng.lat), lngWithinRange(latLng.lng)],
});

export const getMapBoundaries = (map: L.Map): BoundariesWhereInput => {
  const bounds = map.getBounds();
  return {
    ne: latLngToPoint(bounds.getNorthEast()),
    sw: latLngToPoint(bounds.getSouthWest()),
    size: { type: 'Point', coordinates: [map.getSize().x, map.getSize().y] },
  };
};
